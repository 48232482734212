
/* sub-visual */
.sub-visual {
    &__title {
        padding: 30px 0 30px;
        display: flex;
        align-items: center;
    }
    .pointed-title {
        &::after {
            right: -26px;
        }
    }
}
@media (min-width: 768px) {
    .sub-visual {
        &__image {
            height: 300px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
        }
    }
}
@media (min-width: 1464px) {
    .sub-visual {
        &__title {
            padding: 100px 0 60px;
            height: 270px;
        }
        &__image {
            background-size: 110%;
            background-attachment: fixed;
            background-position: center;
        }
        .pointed-title {
            &::after {
                right: -36px;
            }
        }
    }
}
@media (min-width: 1920px) {
    .sub-visual {
        &__title {
            padding: 100px 0 60px;
            height: 270px;
        }
        &__image {
            background-size: contain;
            background-attachment: fixed;
            background-position: center 18px;
        }
        .pointed-title {
            &::after {
                right: -36px;
            }
        }
    }
}
